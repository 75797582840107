<template>
  <div class="home">
    <section id="homehead">
      <h1 class="maintitle">PRODUCTION<br>VIDEO</h1>      
      <p class="maintext">Tels des feux de navigation, les vidéos vous permettent d'être visible dans l'océan d'internet. Elles sont un moyen de communication extrêmement puissant, que ce soit pour présenter votre entreprise, vos produits, pour rendre compte d'un événement ou faire passer des informations.</p>
    </section>
    <section id="anchor" class="services__card">
      <anchor-router-link class="services__card__one skewleft" :to="{hash: '#services__offres'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
                                    <div class="services__card__content skewright">
              <i class="fas fa-tasks services__card__icon"></i>
              <h2 class="services__card__title">LES OFFRES</h2></div>
      </anchor-router-link>
      <anchor-router-link class="services__card__one__center skewright" :to="{hash: '#services__process'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
                                    <div class="services__card__content center skewleft">
        <i class="fas fa-cogs services__card__icon"></i>
        <h2 class="services__card__title">LE PROCESS</h2>
        </div>
      </anchor-router-link>
      <anchor-router-link class="services__card__one skewleft" :to="{hash: '#services__price'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
                                    <div class="services__card__content skewright">
        <i class="fas fa-money-bill-wave services__card__icon"></i>
        <h2 class="services__card__title">LES TARIFS</h2>
        </div>
      </anchor-router-link>
    </section>
    <section id="services__offres" class="sovid">
      <h2 class="services__title">LES OFFRES</h2>
      <div class="sticky">
      <img src="../assets/video2.svg" alt="Illustration d'un ordinateur sur un player vidéo" class="sticky__img">      
      <Vidtext/>
      </div>
      <div class="sticky__no">
        <img src="../assets/video2.svg" alt="Illustration d'un ordinateur sur un player vidéo" class="services__offres__img revealleft">
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">TYPES DE VIDEOS</h3>
          <p class="services__offres__text">•	Je vous propose différents types de vidéos : témoignage d'un client, présentation d'un produit, film institutionnel, reportage événementiel ou sportif, teaser, bande-annonce... Je suis à l'écoute de tous vos projets ! Nous déterminerons ensuite ensemble si votre projet nécessite un tournage ou non, la trame à suivre, les moyens à mettre en œuvre et le style de montage souhaité.</p>
        </div>
        <div class="services__offres__line revealleft">
          <h3 class="services__offres__title">TOURNAGE</h3>
          <p class="services__offres__text">Le tournage consiste à organiser un temps pour faire les prises de vues nécessaires à la réalisation de la vidéo. Il se déroule généralement sur votre lieu d'activité, dans votre entreprise ou chez vos clients. La captation peut se faire avec une caméra sur pied et micro-cravate, pour des plans fixes par exemple, à la main au stabilisateur et micro directionnel pour des plans plus dynamiques, mais aussi au drone ou à la GoPro avec des fixations adaptées pour des angles de vue originaux. En fonction de votre projet, le tournage peut suivre un script ou un storyboard préalablement établi, ou bien capter des moments sur le vif.</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">MOTION DESIGN</h3>
          <p class="services__offres__text">Une vidéo peut intégralement être réalisée en animation ou en motion design. Le motion design est l'art de donner vie à des images (photos, dessins, formes, typographies, illustrations...) en les animant. Ce type de vidéo est un vecteur de communication majeur et permet de transmettre des messages forts, des informations ou des consignes, tout en captant l'attention du public. Son format, généralement court, est également idéal pour la viralité sur les réseaux sociaux et les spots publicitaires.</p>
        </div>
        <div class="services__offres__line revealleft">
        <h3 class="services__offres__title">MONTAGE</h3>
          <p class="services__offres__text">Le montage consiste à assembler les différentes prises de vues et/ou animations afin de créer un ensemble cohérent, souvent de façon dynamique rythmé par les images et le son. D’un montage résulte une vidéo qui a un message à transmettre, tout en étant plaisante à regarder et en captant l'attention du public. Le style de montage est un élément déterminant du temps passé à réaliser une vidéo, et donc de son budget : un montage simple avec peu de coupes sera plus rapide à faire qu’un montage dynamique avec de nombreux plans. Ensemble, nous étudierons en fonction de vos envies et de votre budget, quel type de vidéo est le plus adapté pour votre projet !</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">ANIMATION DE LOGO</h3>
          <p class="services__offres__text">Pour renforcer l’identité de votre entreprise dans les vidéos, je vous propose d’animer votre logo en 2D à partir de votre fichier vectoriel. Cette animation sera pensée avec vous, en fonction de vos envies mais aussi des formes et couleurs de votre logo, et ce, entièrement personnalisé ! Un sound design associé pourra également être réalisé.</p>
        </div>
        <div class="services__offres__line revealleft">
        <h3 class="services__offres__title">SOUND DESIGN</h3>
          <p class="services__offres__text">Le montage vidéo est toujours accompagné de sound design, et notamment pour les vidéos animées ou en motion design. Il consiste à utiliser, mixer, superposer, des éléments sonores afin d'obtenir l'effet sonore voulu : type d’ambiance, bruitage d’éléments, effets de transition… Le sound design permet de renforcer l'intention du montage et de guider le public par le son. Il est à la croisée de la composition musicale et du bruitage.</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">DRONE</h3>
          <p class="services__offres__text">Les vidéos captées en drone permettent d'avoir un tout nouveau regard sur le monde. Qu'elles soient simplement illustratives ou au contraire à but informatif, les images aériennes donnent toujours un point de vue unique. Avec le drone, vos vidéos vont prendre de la hauteur ! Prochainement télépilote certifiée, je me ferai un plaisir de voler pour vos projets !</p>
        </div>
        <div class="services__offres__line revealleft">
        <h3 class="services__offres__title">DOUBLE VALIDATION</h3>
          <p class="services__offres__text">Afin d’assurer votre satisfaction, une première version de la vidéo vous sera présentée. Si tout est parfait : super ! Sinon, vous pourrez me demander, sans frais supplémentaires, des modifications précises, concises, claires et définitives. J’effectuerai ces changements et vous présenterai la vidéo une deuxième fois pour validation. Vous trouverez plus d’informations dans mes Conditions Générales de Vente.</p>
        </div>
      </div> 
    </section>
    <section id="services__process" class="slideshow">
      <Videoslideshow/>
    </section>
    <section id="services__price">
      <h2 class="services__title">LES TARIFS</h2>
    <Tarifsvideo/>
    </section>
  </div>
</template>

<script>
import Videoslideshow from '../components/videoslideshow.vue';
import Vidtext from '../components/vidtext.vue';
import Tarifsvideo from '../components/tarifsvideo.vue';
import $ from 'jquery';
import AnchorRouterLink from 'vue-anchor-router-link'

export default {
  name: 'Home',
  components: {
    Videoslideshow,
    AnchorRouterLink,
    Tarifsvideo,
    Vidtext
  },
  mounted() {
    /********** Apparition au scroll ****/
        var rafId = null;
        var delay = 230;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);
  }
}
</script>

<style lang="scss">

/******************** CARTES HAUT DE PAGE */

.services__card {
    background-image: url('../assets/wavej4.svg');
    background-size: auto;
    background-position-y: center;
    background-repeat: repeat-x;
    padding-top: 100px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  @media screen and (max-width: 980px){
            background-size: cover;
            flex-direction: column;
            padding-top: 50px;
            padding-bottom: 0;
            }
  &__container {
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 80%;
  margin: auto;
  }
  &__one {
    width: 25%;
    box-shadow: 0px 0px 6px 0px grey;
    border-radius: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    box-sizing: border-box;
    transition: all 0.25s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: 50;
    //background: linear-gradient(127deg, #ffffff66 0%, #1967a314 90%, #ffffff66 110%);
    &:hover {
      transform: scale(1.03) skewY(13deg);
    }
    @media (max-width: 980px) {
      width: 90%;
      margin: auto;
      margin-bottom: 50px;
    }
    @media (min-width: 1440px) {
      padding: 50px 50px;
    }
    &__center {
       width: 25%;
    box-shadow: 0px 0px 6px 0px grey;
    border-radius: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    box-sizing: border-box;
    transition: all 0.25s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: 50;
    //background: linear-gradient(127deg, #ffffff66 0%, #1967a314 90%, #ffffff66 110%);
      &:hover {
      transform: scale(1.03) skewY(-13deg);
    }
    @media (max-width: 980px) {
      width: 90%;
      margin: auto;
      margin-bottom: 50px;
      transform: skewY(13deg)!important;
      &:hover {
      transform: scale(1.03) skewY(13deg);
        }
      }
     @media (min-width: 1440px) {
      padding: 50px 50px;
    }
    }
  }
  &__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: xx-large;
    text-align: center;
  }
  &__icon {
  font-size: 400%;
  background: linear-gradient(308deg, #1c09e0cf 0%, #38e01fc7 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  }
}

.skewleft {
  transform: skewY(13deg);
  display: flex;
    flex-direction: column;
    align-items: center;
}

.skewright {
  transform: skewY(-13deg);
  display: flex;
    flex-direction: column;
    align-items: center;
}

.center {
   @media (max-width: 980px) {
      transform: skewY(-13deg)!important;
   }
}

/********************* INTRO SUR IMAGE HAUT DE PAGE */

.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 300px) {
		font-size: small;
    width: 80%;
	}
}

/********************* BLOC OFFRES */


#services__offres {
  position: relative;
  width: 100%;
  margin: auto;
  @media (max-width: 768px) {
    width: 95%;
    height: auto;
	}
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 95%;
    height: auto;
  }
}

.sovid {
  height: 6000px; //reglage hauteur scroll
  @media (max-width: 768px) {
    height: auto;
	}
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: auto;
  }
}


.sticky {
  position: sticky;
  display: flex;
  top: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-image: url('../assets/wave2j1.svg');
  background-size: auto;
  background-position-y: center;
  background-repeat: repeat-x;
  -webkit-transform: translate(0,-50%);
  transform: translate(0,-50%);
  margin-top: 30%;
  margin-bottom: -10%;
  @media screen and (max-width: 769px){
            display: none;
            }
  @media only screen and (min-device-width : 769px) and (max-device-width : 1024px) and (orientation : portrait) {
    display: none;
  }
  &__img {
    width: 40%;
  }
  &__no {
    width: 90%;
    margin: auto;
        display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    overflow: hidden;
    @media screen and (min-width: 769px){
            display: none;
            }
    @media only screen and (min-device-width : 769px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 90%;
    margin: auto;
        display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    overflow: hidden; ;
    }
  }
}

.services__offres {
  &__title {
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        text-align: left;
        &::before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: calc( 50% - 1.5px );
        border-bottom: 20px solid #38e01f;
        z-index: -1;
        }
        @media screen and (max-width: 980px){
                margin-top: 60px;
                }
    }
    &__text {
      font-size: small;
      text-align: left;
    }
    &__img {
      width: 90%;
      margin: auto;
    margin-top: 20px;
    }

}

/****************** BLOC PRIX */

#services__price {
  width: 100%
}

 .services__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 90%;
    margin: auto;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01f;
    z-index: -1;
    }
    @media screen and (max-width: 980px){
            margin-top: 60px;
            }
  }

/********************* APPARITION AU SCROLL */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
</style>
