<template>
 <div class="price__table">
        <div class="table__infomobile">
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/026-surf.svg" class="table__infomobile__icon">PACK SURF</h3>
          <p class="table__infomobile__text">Vidéo virale : une courte vidéo simple, avec peu de coupes, de présentation d'un produit, projet, événement, de témoignage client, ou toute autre courte infographie vidéo destinée à vos réseaux sociaux.</p>
        </div>
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/027-kite.svg" class="table__infomobile__icon">PACK KITE</h3>
          <p class="table__infomobile__text">Clip : Une vidéo de durée moyenne pour des courts reportages, lyrics vidéo, clips, présentations détaillées ou encore bandes annonces.</p>
        </div>
        <div class="table__infomobile__block">
          <h3 class="table__infomobile__title"><img src="../assets/020-windsurfing.svg" class="table__infomobile__icon">PACK WIND</h3>
          <p class="table__infomobile__text">Film : selon vos besoins et vos envies, je réaliserai une vidéo de la durée de votre choix : grand reportage, vidéo souvenir, film institutionnel, tutoriel... je suis à l'écoute de tous vos projets !</p>
        </div>
      </div> 
      <table class="table__spec" align="center" width="41%">
          <tr class="table__linedesktop">
            <td class="table__empty">
            </td>
            <td class="table__empty">
              <img src="../assets/026-surf.svg" alt="Illustration" class="table__illus">
              <p class="table__title">SURF</p>
            </td>
            <td class="table__empty">
              <img src="../assets/027-kite.svg" alt="Illustration" class="table__illus">
              <p class="table__title">KITE</p>
            </td>
            <td class="table__empty">
              <img src="../assets/020-windsurfing.svg" alt="Illustration" class="table__illus">
              <p class="table__title">WINDSURF</p>
            </td>
          </tr>
          <tr class="table__linedesktop">
            <td>
              <p>Votre planche</p>
            </td>
            <td>
              <p>Vidéo virale</p>
            </td>
            <td>
              <p>Clip</p>
            </td>
            <td>
              <p>Film</p>
            </td>
          </tr>
          <tr class="table__linedesktop">
            <td>
              <p>Votre besoin</p>
            </td>
            <td>
              <p>Une courte vidéo simple, avec peu de coupes, de présentation d'un produit, projet, événement, de témoignage client, ou toute autre courte infographie vidéo destinée à vos réseaux sociaux.</p>
            </td>
            <td>
              <p>Une vidéo de durée moyenne pour des reportages, lyrics vidéo, clips, présentations détaillées ou encore bandes annonces.</p>
            </td>
            <td>
              <p>Selon vos besoins et vos envies, je réaliserai une vidéo de la durée de votre choix : grand reportage, vidéo souvenir, film institutionnel, tutoriel... je suis à l'écoute de tous vos projets !</p>
            </td>
          </tr>
          <tr class="table__linemobile">
            <td class="table__empty">
            </td>
            <td class="table__empty">
              <img src="../assets/026-surf.svg" class="table__infomobile__icon">
            </td>
            <td class="table__empty">
              <img src="../assets/027-kite.svg" class="table__infomobile__icon">
            </td>
            <td class="table__empty">
              <img src="../assets/020-windsurfing.svg" class="table__infomobile__icon">
            </td>
          </tr>
          <tr>
            <td>
              <p>Durée (à titre indicatif)</p>
            </td>
            <td>
              <p>jusqu'à 1min30</p>
            </td>
            <td>
              <p>de 1min30 à 3min</p>
            </td>
            <td>
              <p>plus de 3 minutes</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Tournage sur votre lieu d'activité...</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>...ou création avec vos images et/ou en motion design</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Montage</p>
            </td>
            <td>
              <p>simple, peu de coupes (style Konbini)</p>
            </td>
            <td>
              <p>dynamique</p>
            </td>
            <td>
              <p>dynamique</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Sound Design</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Sous-titrage</p>
            </td>
            <td>
              <p>en sus</p>
            </td>
            <td>
              <p>en sus</p>
            </td>
            <td>
              <p>en sus</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Optimisation aux formats des réseaux sociaux</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Livraison de la vidéo en double format : DNxHD pour la diffusion Haute Qualité et mp4 pour les réseaux sociaux</p>
            </td>
            <td>
              <p><i class="fas fa-times"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Réalisation d'un teaser du film</p>
            </td>
            <td>
              <p><i class="fas fa-times"></i></p>
            </td>
            <td>
              <p>en sus</p>
            </td>
            <td>
              <p><i class="fas fa-check"></i></p>
            </td>
          </tr>
    </table>
    <table class="table__price">
      <tr>
            <td>
              <p>TARIFS</p>
            </td>
            <td>
              <p>A partir de 200€HT</p>
            </td>
            <td>
              <p>A partir de 400€HT</p>
            </td>
            <td>
              <p>A partir de 800€HT</p>
            </td>
          </tr>
    </table>
    <p class="smallprint">*Ces tarifs de base sont donnés à titre indicatif. Le tarif définitif de votre projet sera établi sur devis contractuel après avoir analysé ensemble vos besoins, vos envies et votre budget. N'hésitez pas à me contacter, le devis est gratuit !</p>   
    <router-link to="Contact"><div class="devis">ME DEMANDER UN DEVIS</div></router-link>
 </div>
</template>

<script>
//import $ from 'jquery'

export default {
  name: '',
  props: {
    msg: String
  },
    mounted() {
    }
}
</script>

<style lang="scss">
.price__table {
  background-image: url('../assets/wave2j1.svg');
 background-size: auto;
    background-position-y: center;
    background-repeat: repeat-x;
  @media screen and (max-width: 769px){
            background-size: cover;
            background-position-y: 300px;
            }
}

table {
  width: 81%;
  margin: auto;
  background-origin: border-box;  
  border-spacing: 0px 1px;
}

.table__price {
  margin-top: 20px;
  font-weight: bold;
  font-size: medium;
  @media (max-width: 768px) {
		width: 90%;
    font-size: small;
	}
}

.table__spec {
  font-size: medium;
  @media (max-width: 768px) {
		width: 90%;
    font-size: x-small;
	}
}

.table__linedesktop {
  @media (max-width: 768px) {
		display: none;
	}
}

.table__linemobile {
  @media (min-width: 769px) {
		display: none;
	}
}

.table__infomobile {
  margin: auto;
  width: 90%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  @media (min-width: 769px) {
		display: none;
	}
  @media (min-width: 650px) {
		flex-direction: row;
    justify-content: space-between;
	}
  &__title {
    font-family: 'Yanone Kaffeesatz';
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 7px;
    height: 30px;
    width: auto;
  }
  &__text {
    font-size: small;
  }
  &__block {
    margin-bottom: 15px;
    @media (min-width: 650px) {
		width: 30%;
	}
  }
}

td {
  background: transparent;
  text-align: center;
  height: 40px;
  width: 25%;
  font-size: inherit;
  padding: 4px 16px;
  border-top: 1px solid #386ed8;
  @media (max-width: 768px) {
		padding: 4px 0px;
	}
}

.table__illus {
  margin: auto;
  margin-bottom: 10px;
  height: 150px;
    width: auto;
}

.table__title {
  font-family: 'Yanone Kaffeesatz';
  font-size: x-large;
}

.table__empty {
  border: none;
}

.fa-check {
  background: #38e01fc7;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: initial;
}

.fa-times {
  background: #1c09e0cf;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: initial;
}

.smallprint {
  width: 90%;
    margin: auto;
    margin-top: 30px;
    font-size: x-small;
}

.devis {
    border: 2px solid #386ed8;
    width: fit-content;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px 15px 12px;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: 'Yanone Kaffeesatz';
    font-size: xx-large;
    transition: all 0.25s;
     &:hover {
            background-color: #386ed8;
            box-shadow: 0px 2px 5px 0px black;
            color: white!important;
            transform: scale(1.02);
        }
        &:focus {
            background-color: lighten($color: #386ed8, $amount: 30);
            box-shadow: 0px 2px 5px 0px black;
            color: white;
            transform: scale(1.02);
        }
  @media screen and (max-width: 425px){
            width: 90%;
            text-align: center;
            margin-bottom: 20px;
            }
}

</style>