<template>
<div class="container">
    <div id="content"></div>
  </div>
</template>

<script>

export default {
  components: {
  },
  mounted() {
      // https://john-dugan.com/javascript-debounce/
function debounce(e, t, n) {
  var a;
  return function () {
    var r = this,
      i = arguments,
      o = function () {
        a = null, n || e.apply(r, i)
      },
      s = n && !a;
    clearTimeout(a), a = setTimeout(o, t || 200), s && e.apply(r, i)
  }
}

const texts = [
  `<div class="services__offres__text"><h3 class="services__offres__title">TYPES DE VIDEOS</h3><p class="services__offres__text">Je vous propose différents types de vidéos : témoignage d'un client, présentation d'un produit, film institutionnel, reportage événementiel ou sportif, teaser, bande-annonce... Je suis à l'écoute de tous vos projets ! Nous déterminerons ensuite ensemble si votre projet nécessite un tournage ou non, la trame à suivre, les moyens à mettre en œuvre et le style de montage souhaité.</p></div><div class="services__offres__dotblock"><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">TOURNAGE</h3><p class="services__offres__text">Le tournage consiste à organiser un temps pour faire les prises de vues nécessaires à la réalisation de la vidéo. Il se déroule généralement sur votre lieu d'activité, dans votre entreprise ou chez vos clients. La captation peut se faire avec une caméra sur pied et micro-cravate, pour des plans fixes par exemple, à la main au stabilisateur et micro directionnel pour des plans plus dynamiques, mais aussi au drone ou à la GoPro avec des fixations adaptées pour des angles de vue originaux. En fonction de votre projet, le tournage peut suivre un script ou un storyboard préalablement établi, ou bien capter des moments sur le vif.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">MOTION DESIGN</h3><p class="services__offres__text">Une vidéo peut intégralement être réalisée en animation ou en motion design. Le motion design est l'art de donner vie à des images (photos, dessins, formes, typographies, illustrations...) en les animant. Ce type de vidéo est un vecteur de communication majeur et permet de transmettre des messages forts, des informations ou des consignes, tout en captant l'attention du public. Son format, généralement court, est également idéal pour la viralité sur les réseaux sociaux et les spots publicitaires.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">ANIMATION DE LOGO</h3><p class="services__offres__text">Pour renforcer l’identité de votre entreprise dans les vidéos, je vous propose d’animer votre logo en 2D à partir de votre fichier vectoriel. Cette animation sera pensée avec vous, en fonction de vos envies mais aussi des formes et couleurs de votre logo, et ce, entièrement personnalisé ! Un sound design associé pourra également être réalisé.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">MONTAGE</h3><p class="services__offres__text">Le montage consiste à assembler les différentes prises de vues et/ou animations afin de créer un ensemble cohérent, souvent de façon dynamique rythmé par les images et le son. D’un montage résulte une vidéo qui a un message à transmettre, tout en étant plaisante à regarder et en captant l'attention du public. Le style de montage est un élément déterminant du temps passé à réaliser une vidéo, et donc de son budget : un montage simple avec peu de coupes sera plus rapide à faire qu’un montage dynamique avec de nombreux plans. Ensemble, nous étudierons en fonction de vos envies et de votre budget, quel type de vidéo est le plus adapté pour votre projet !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">SOUND DESIGN</h3><p class="services__offres__text">Le montage vidéo est toujours accompagné de sound design, et notamment pour les vidéos animées ou en motion design. Il consiste à utiliser, mixer, superposer, des éléments sonores afin d'obtenir l'effet sonore voulu : type d’ambiance, bruitage d’éléments, effets de transition… Le sound design permet de renforcer l'intention du montage et de guider le public par le son. Il est à la croisée de la composition musicale et du bruitage.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">DRONE</h3><p class="services__offres__text">Les vidéos captées en drone permettent d'avoir un tout nouveau regard sur le monde. Qu'elles soient simplement illustratives ou au contraire à but informatif, les images aériennes donnent toujours un point de vue unique. Avec le drone, vos vidéos vont prendre de la hauteur ! Prochainement télépilote certifiée, je me ferai un plaisir de voler pour vos projets !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">DOUBLE VALIDATION</h3><p class="services__offres__text">Afin d’assurer votre satisfaction, une première version de la vidéo vous sera présentée. Si tout est parfait : super ! Sinon, vous pourrez me demander, sans frais supplémentaires, des modifications précises, concises, claires et définitives. J’effectuerai ces changements et vous présenterai la vidéo une deuxième fois pour validation. Vous trouverez plus d’informations dans mes Conditions Générales de Vente.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`
 ]

const stepSize = window.innerHeight

function setText(contentTag, text) {
  contentTag.innerHTML = text
}

function getIndex(distance, stepSize) {
  return parseInt(distance / stepSize)
}

function getText(texts, index) {
  return texts[index] ? texts[index] : `<div class="services__offres__text"><h3 class="services__offres__title">DOUBLE VALIDATION</h3><p class="services__offres__text">Afin d’assurer votre satisfaction, une première version de la vidéo vous sera présentée. Si tout est parfait : super ! Sinon, vous pourrez me demander, sans frais supplémentaires, des modifications précises, concises, claires et définitives. J’effectuerai ces changements et vous présenterai la vidéo une deuxième fois pour validation. Vous trouverez plus d’informations dans mes Conditions Générales de Vente.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`
}

function changeText(contentTag, stepSize, texts) {
  const distance = window.scrollY -700
  const index = getIndex(distance, stepSize)
  const text = getText(texts, index)
  setText(contentTag, text)
}

function setInittext(contentTag, texts) {
  const text = getText(texts, 0)
  setText(contentTag, text)
}

function loadText() {
  const contentTag = document.querySelector('#content')
  setInittext(contentTag, texts)
  window.addEventListener('scroll', debounce(() => { changeText(contentTag, stepSize, texts) }, 20))
}

loadText();
  }
}
</script>

<style lang="scss">


.container {
  height: auto; 
}


#content {
    width: 420px;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
}

.services__offres__dotblock {
  display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.fa-circle {
  color: #26262696;
    font-size: 6px;
  margin: 5px 0;
}
</style>